// Functions copied (until they are exported otherwise) from @CondeNast/copilot-markdown
// @condenast/copilot-markdown/dist/plugins/copilot/rules/helpers/renderTemplatedRule.js
export function findEmbedData(view, env) {
  let data = env.data;
  if (typeof data !== 'object') {
    return null;
  }
  const fields = [view.type, view.subtype, view.uri];
  for (let i = 0; i < fields.length; i++) {
    data = data[fields[i]];
    if (i === fields.length - 1) {
      return data;
    }
    if (typeof data !== 'object') {
      break;
    }
  }
  return null;
}

export function findData(view, env) {
  switch (view.type) {
    case 'embed':
      return findEmbedData(view, env);
    default:
      return {};
  }
}

// @condenast/copilot-markdown/dist/plugins/copilot/rules/helpers/materialize.js
export function contentFieldWith(func) {
  return function (acc, pair) {
    acc[pair[0]] = func(pair[1]);
    return acc;
  };
}
