import React from 'react';

import ReactRenderer from './renderer';

/**
 * Convert copilot-markdown documents into React elements.
 *
 * @param {Object} md - An instance of copilot-markdown (or compatible)
 * @param {Object} templates? - Template-functions for document
 * @returns {Function} mdToReact
 * @public
 */
export function mdToReact(md, templates = {}) {
  /**
   * @param {String} content - A document input string
   * @param {Object} embedData? - Data to pass to template-functions
   * @param {Object} options? - Global values to pass to all template-functions
   * @returns {Array<Object|String>|null} - Rendered React elements
   */
  return function mdToReact(content, embedData = [], options = {}) {
    const environment = {
      templates,
      data: {
        embed: embedData,
        globals: options
      }
    };
    return md.render(md.parse(content), new ReactRenderer(), environment);
  };
}

/**
 * Unwrap the inner copilot-markdown document contents from the first-level
 * paragraph enclosure if one exists.
 *
 * (document)  =>  (document)
 *  |               |
 * [paragraph]     [text:"foo"]
 *  |
 * [text:"foo"]
 *
 * Note, this only affects the first-level paragraph within the document (if it
 * exists). Any subsequent paragraph containers within interior descendants will
 * _not_ be modified.
 *
 * @param {Object} md - An instance of copilot-markdown (or compatible)
 * @returns {Function} mdToReactUnwrapped
 * @public
 */
export function mdToReactUnwrapped(md) {
  /**
   * @param {String} content - A document input string
   * @returns {Array<Object|String>|null} - Rendered React elements without wrap
   */
  return function mdToReactUnwrapped(content) {
    let reactBody = mdToReact(md)(content);
    if (Array.isArray(reactBody)) {
      reactBody = reactBody[0];
    }
    if (React.isValidElement(reactBody) && reactBody.type === 'p') {
      reactBody = reactBody.props.children;
    }
    return reactBody;
  };
}
