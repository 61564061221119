import * as _ from 'lodash';
import React from 'react';
import voidElementTags from 'void-elements';

import {
  contentFieldWith,
  findData
} from './core';

const internals = {};

// React 15 has a specific DOM set, but React 16 does not. We're supporting both.
internals.reactHTMLTags = React.DOM && Object.keys(React.DOM);

const materializeOrSo = function materializeOrSo(token) {
  const view = {
    type: token.meta.type,
    subtype: token.meta.subtype,
    level: token.meta.level
  };
  token.meta.content.raw.reduce(contentFieldWith(x => x), view);
  return view;
};

export const canCloseToken = function canCloseToken(currentToken, collectedToken) {
  // check the currentToken is a close tag
  if (currentToken.nesting !== -1) return false;

  // check it matches the collected opened tag
  if (currentToken.tag !== collectedToken.tag) return false;

  // block tokens can close if they're on the same level as the collected token
  if (currentToken.block === true) {
    return (currentToken.level === collectedToken.level);
  }

  // inline blocks can close if they're one level up
  return (currentToken.level === collectedToken.level - 1);
};

export default class ReactRenderer {

  constructor() {
    this.count = 0;
  }

  getConverter(token, environment) {
    let converter;
    if (environment.templates.hasOwnProperty(token.type)) {
      converter = environment.templates[token.type];
      if (converter.hasOwnProperty(token.info)) {
        converter = converter[token.info];
      } else if (converter.hasOwnProperty('default')) {
        converter = converter.default;
      }
    }
    return converter;
  }

  createElementFromToken(token, environment, childrenArg) {
    let element = null;
    let type = token.tag;
    let props = {key: this.count};
    const converter = this.getConverter(token, environment);
    const children = voidElementTags[token.tag] !== true ? childrenArg : undefined;

    // Ignore softbreaks, per the markdown.it defaults
    // https://github.com/markdown-it/markdown-it/blob/e6f19eab4204122e85e4a342e0c1c8486ff40c2d/lib/presets/commonmark.js#L10
    if (type === 'br' && token.type === 'softbreak') {
      return null;
    }

    if (token.attrs) {
      Object.assign(props, _.fromPairs(token.attrs));
    }

    if (typeof converter === 'function') {
      const dataItem = findData(materializeOrSo(token), environment);
      const result = converter(token, props, dataItem, environment.data.globals, children);
      type = result.type || type;
      props = result.props || props;
    }

    if (token.type === 'text') {
      element = token.content;
    } else if (
      // React 16: there's no list of valid tags, so we'll let them all through
      !internals.reactHTMLTags ||

      // React 15: we only let through valid React.DOM tags, or void elements, or templated tags.
      (internals.reactHTMLTags.indexOf(type) !== -1 || voidElementTags[token.tag] === true || typeof converter === 'function')
    ) {
      element = React.createElement(type, props, children);
      this.count++;
    }

    return element;
  }

  render(tokens, opts, environment) {
    if (!tokens) {
      return null;
    }

    const state = {
      collectedToken: null
    };
    let externalAccumulator = [];

    let reduced = _.reduce(tokens, (acc, token) => {
      let element;

      // collect pre-rendered items
      if (React.isValidElement(token)) {
        element = token;

        // Have to unwrap inlines no matter where they appear
      } else if (token.type === 'inline') {
        element = token.children;

        // Unwrap tokens with children
      } else if (token.children && token.children.length > 0) {
        const children = this.render(token.children, opts, environment);
        element = this.createElementFromToken(token, environment, children);

        // If we're at the base level, we can either bump up a level, or render
      } else if (state.collectedToken === null) {
        if (token.nesting === 1) {
          state.collectedToken = token;
        } else {
          element = this.createElementFromToken(token, environment);
        }

        // If we're at a nested level of 1, we can either bump down a level (and render), or collect
      } else if (canCloseToken(token, state.collectedToken)) {
        const children = externalAccumulator.length ? this.render(externalAccumulator, opts, environment) : externalAccumulator;
        element = this.createElementFromToken(state.collectedToken, environment, children);
        // reset state
        state.collectedToken = null;
        externalAccumulator = [];

      } else {
        element = token;
      }

      if (element) {
        if (state.collectedToken === null) {
          acc = acc.concat(element);
        } else {
          externalAccumulator = externalAccumulator.concat(element);
        }
      }

      return acc;
    }, []);

    // In case any elements got collected without a closing tag
    if (externalAccumulator.length > 0) {
      reduced = reduced.concat(this.render(externalAccumulator, opts, environment));
    }

    return reduced;
  }
}

export const __internals__ = internals;
